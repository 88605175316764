
// utils/firebaseFunctionCaller.js

import { auth } from "../firebaseConfig";

/**
 * Calls an HTTP function deployed on Firebase using a valid access token.
 * @param {string} functionName - Name of the deployed function.
 * @param {Object} data - Data sent in the request.
 * @returns {Promise<Object>} - JSON response from the function.
 */
export async function callFirebaseFunction(functionName, data = {}) {
  // Check if there is an authenticated user
  const user = auth.currentUser;
  if (!user) {
    console.error("callFirebaseFunction: Error - User is not authenticated.");
    throw new Error("User is not authenticated.");
  }

  try {
    // Obtain the access token
    const idTokenResult = await user.getIdTokenResult();
    const accessToken = idTokenResult.token;

    // Construct the URL
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    if (!baseUrl) {
      throw new Error("Base URL is not defined. Check your environment variables.");
    }

    const url = `${baseUrl}/${functionName}`;
    
    // Set up the headers
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    };

    // Make the request
    const response = await fetch(url, requestOptions);

    // Handle network errors
    if (!response.ok) {
      const errorText = await response.text();
      console.error("callFirebaseFunction: Server error:", errorText);

      if (response.status === 403 || response.status === 401) {
        throw new Error("Authorization error. Please check your token or permissions.");
      }

      throw new Error(errorText || "Server Error");
    }

    // Handle the response
    const responseBody = await response.json();
    console.log("callFirebaseFunction: Response received:", JSON.stringify(responseBody, null, 2));

    return responseBody;
  } catch (error) {
    // Handle network and CORS errors
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      console.error("callFirebaseFunction: Network or CORS error.");
      throw new Error(
        "Network error or CORS issue. Please check your backend function's CORS policy."
      );
    }

    console.error("callFirebaseFunction: Error during the request:", error);
    console.error("callFirebaseFunction: Possible causes:");
    console.error("- Check that the URL is correct.");
    console.error("- Ensure the access token is valid.");
    console.error("- Review the CORS policies on the backend.");
    throw error;
  }
}
