import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callFirebaseFunction } from "@/utils/firebaseFunctionCaller";
import { parseSimulationResult } from "@/middleware/parseSimulationResult";
import { CATEGORY_TYPES } from "@/utils/categories";
import { PHASES_OF_THE_MATCH } from "@/constants/phasesOfTheMatch";
import { updatePlayerEnergyLevels } from "@/features/teamSlice";
import { annotateTimelineWithScore } from "@/utils/scoreboardUtils";
import { updatePlayersFromEvents } from "@/utils/updatePlayersFromEvents";
import { CHRONOLOGY_SIDE } from "@/constants/chronologySide";

export const MATCH_TIME_STEP = 27;
export const MAX_MATCH_TIME = 5400;
export const INTERVAL_DELAY = 100;

const initialState = {
  loadingTimeline: false,
  loadingStatistics: false,
  errorTimeline: null,
  errorStatistics: null,
  timeline: [],
  attackPercentage: [],
  technicianAdvice: null,
  highlights: [],
  completedMatchData: null,
  simulationCompleted: false,
  hasSimulated: false,
  navigationTriggered: false,
  localShotsData: { field: [], goal: [] },
  opponentShotsData: { field: [], goal: [] },
  playerHeatmaps: {},
  matchTime: 0,
  isMatchTimeRunning: false,
  phase: PHASES_OF_THE_MATCH.NOT_STARTED,
  scoreboard: {
    local: { goals: [], redCards: [] },
    opponent: { goals: [], redCards: [] },
  },
};

const fetchData = async (functionName, data) => {
  try {
    const response = await callFirebaseFunction(functionName, data);
    if (!response.success) {
      throw new Error(response.message || "Unexpected error from simulation function.");
    }
    return response;
  } catch (err) {
    console.error(`[matchSimulation] Function ${functionName} error:`, err);
    throw new Error(err.message || "Backend function invocation failed.");
  }
};

const timeToMinutes = (timeString) => {
  const [minutes, seconds] = timeString.split(":").map(Number);
  return minutes + seconds / 60;
};

const ensureAscendingTimeline = (timeline) => {
  if (timeline.length < 2) return timeline;
  const firstMinute = timeToMinutes(timeline[0].minute);
  const lastMinute = timeToMinutes(timeline[timeline.length - 1].minute);
  return firstMinute > lastMinute ? timeline.slice().reverse() : timeline;
};

const fillAttackPercentage = (existingDataMap, length = 46) => {
  return Array.from({ length }, (_, idx) => {
    const minute = idx * 2;
    return (
      existingDataMap.get(minute) || {
        minute,
        local: Math.floor(Math.random() * 30) + 1,
        opponent: Math.floor(Math.random() * 30) + 1,
      }
    );
  });
};

export const fetchSimulationTimeline = createAsyncThunk(
  "matchSimulation/fetchSimulationTimeline",
  async (matchData, { rejectWithValue }) => {
    try {
      const result = await fetchData("simulate_match", matchData);
      const {
        timeline = [],
        attackPercentage = [],
        playersStatus,
        technicianAdvice,
      } = parseSimulationResult(result) || {};

      if (!Array.isArray(timeline) || timeline.length === 0) {
        throw new Error("No timeline events found in simulation result.");
      }

      const orderedTimeline = ensureAscendingTimeline(timeline);
      const annotated = annotateTimelineWithScore(orderedTimeline);

      const mapAttackData = new Map(
        attackPercentage.map(({ minute, ...rest }) => [minute, rest])
      );
      const finalAttack = fillAttackPercentage(mapAttackData);

      const toStatusArray = (team) =>
        Array.isArray(team?.players)
          ? team.players.map((p) => ({
              playerNumber: p.playerNumber,
              status: p.status,
            }))
          : [];

      const localSts = toStatusArray(playersStatus.local);
      const oppSts = toStatusArray(playersStatus.opponent);

      return {
        timeline: annotated,
        attackPercentage: finalAttack,
        technicianAdvice,
        playersStatus: {
          localStatuses: localSts,
          opponentStatuses: oppSts,
        },
      };
    } catch (err) {
      return rejectWithValue(err.message || "Timeline fetch failed.");
    }
  }
);

export const fetchCompleteMatchData = createAsyncThunk(
  "matchSimulation/fetchCompleteMatchData",
  async (fullMatchData, { rejectWithValue }) => {
    try {
      console.log("[fetchCompleteMatchData] input data:", fullMatchData);
      return fullMatchData; // Potential extension for more advanced analysis
    } catch (err) {
      return rejectWithValue(err.message || "Unable to fetch full match data.");
    }
  }
);

export const initiateMatchSimulation = createAsyncThunk(
  "matchSimulation/initiateMatchSimulation",
  async (matchData, { dispatch, rejectWithValue }) => {
    try {
      const timelineResult = await dispatch(fetchSimulationTimeline(matchData)).unwrap();
      const { localStatuses, opponentStatuses } = timelineResult.playersStatus;
      dispatch(updatePlayerEnergyLevels({
        teamStatuses: {
          local: localStatuses,
          opponent: opponentStatuses,
        },
      }));
      return timelineResult;
    } catch (err) {
      return rejectWithValue(err.message || "Simulation could not be initiated.");
    }
  }
);

const matchSimulationSlice = createSlice({
  name: "matchSimulation",
  initialState,
  reducers: {
    initiateSimulation: (state) => {
      state.hasSimulated = false;
      state.simulationCompleted = false;
      state.errorTimeline = null;
      state.errorStatistics = null;
      state.navigationTriggered = false;
      state.matchTime = 0;
      state.isMatchTimeRunning = false;
      state.technicianAdvice = null;
    },
    resetSimulationState: () => initialState,
    markNavigationTriggered: (state) => {
      state.navigationTriggered = true;
    },
    setError: (state, { payload }) => {
      state.errorTimeline = payload;
      state.errorStatistics = payload;
    },
    storeLocalShotsData: (state, { payload: { field = [], goal = [] } }) => {
      state.localShotsData = { field, goal };
    },
    storeOpponentShotsData: (state, { payload: { field = [], goal = [] } }) => {
      state.opponentShotsData = { field, goal };
    },
    storePlayerHeatmap: (state, { payload: { playerId, heatmapData } }) => {
      state.playerHeatmaps[playerId] = heatmapData;
    },
    incrementMatchTime: (state) => {
      state.matchTime = Math.min(state.matchTime + MATCH_TIME_STEP, MAX_MATCH_TIME);
      if (state.matchTime >= MAX_MATCH_TIME) {
        state.simulationCompleted = true;
        state.isMatchTimeRunning = false;
      }
    },
    resetMatchTime: (state) => {
      state.matchTime = 0;
    },
    setMatchTimeRunning: (state, { payload }) => {
      state.isMatchTimeRunning = payload;
    },
    setMatchPhase: (state, { payload }) => {
      state.phase = payload;
    },
    updateScoreboard: (state, { payload }) => {
      state.scoreboard = {
        local: {
          goals: payload.local?.goals ?? state.scoreboard.local.goals,
          redCards: payload.local?.redCards ?? state.scoreboard.local.redCards,
        },
        opponent: {
          goals: payload.opponent?.goals ?? state.scoreboard.opponent.goals,
          redCards: payload.opponent?.redCards ?? state.scoreboard.opponent.redCards,
        },
      };
    },
    updateGoals: (state, { payload }) => {
      const { team, goals } = payload;
      const combined = [...state.scoreboard[team].goals, ...goals];
      const uniqueGoals = new Map(combined.map((g) => [`${g.name}-${g.minute}`, g]));
      state.scoreboard[team].goals = Array.from(uniqueGoals.values());
    },
    updateRedCards: (state, { payload }) => {
      const { team, redCards } = payload;
      const combined = [...state.scoreboard[team].redCards, ...redCards];
      const uniqueCards = new Map(combined.map((c) => [`${c.name}-${c.minute}`, c]));
      state.scoreboard[team].redCards = Array.from(uniqueCards.values());
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSimulationTimeline.pending, (state) => {
        state.loadingTimeline = true;
        state.errorTimeline = null;
      })
      .addCase(fetchSimulationTimeline.fulfilled, (state, { payload }) => {
        const { FIRST_HALF, SECOND_HALF, EXTRA_TIME, PENALTIES, FULL_MATCH } = PHASES_OF_THE_MATCH;

        if (state.phase === FIRST_HALF) {
          state.timeline = ensureAscendingTimeline(payload.timeline);
          state.attackPercentage = payload.attackPercentage;
          state.technicianAdvice = payload.technicianAdvice;
          state.playersStatus = payload.playersStatus;
        } else if ([SECOND_HALF, EXTRA_TIME, PENALTIES].includes(state.phase)) {
          const existingTimes = new Set(state.timeline.map((item) => item.minute));
          const newEvents = payload.timeline.filter((item) => !existingTimes.has(item.minute));
          state.timeline = ensureAscendingTimeline([...state.timeline, ...newEvents]);

          const existingAttackMap = new Map(
            state.attackPercentage.map(({ minute, ...rest }) => [minute, rest])
          );
          payload.attackPercentage.forEach(({ minute, ...rest }) => {
            if (!existingAttackMap.has(minute)) {
              existingAttackMap.set(minute, rest);
            }
          });
          state.attackPercentage = Array.from(existingAttackMap).map(([m, val]) => ({ minute: m, ...val }))
            .sort((a, b) => a.minute - b.minute);

          const mergeEnergy = (prev = [], next = []) => {
            const map = new Map(prev.map((p) => [p.playerNumber, { ...p }]));
            next.forEach(({ playerNumber, status }) => {
              const old = map.get(playerNumber);
              if (old) {
                map.set(playerNumber, {
                  ...old,
                  status: {
                    ...old.status,
                    energyLevel: status.energyLevel,
                  },
                });
              } else {
                map.set(playerNumber, { playerNumber, status });
              }
            });
            return Array.from(map.values());
          };

          const prevLocal = state.playersStatus?.localStatuses || [];
          const prevOpp = state.playersStatus?.opponentStatuses || [];
          state.playersStatus = {
            localStatuses: mergeEnergy(prevLocal, payload.playersStatus.localStatuses),
            opponentStatuses: mergeEnergy(prevOpp, payload.playersStatus.opponentStatuses),
          };

          if (!state.technicianAdvice) {
            state.technicianAdvice = payload.technicianAdvice;
          }
        } else if (state.phase === FULL_MATCH) {
          state.timeline = ensureAscendingTimeline(payload.timeline);
          state.attackPercentage = payload.attackPercentage;
          state.technicianAdvice = payload.technicianAdvice;
          state.playersStatus = payload.playersStatus;
        }

        state.timeline = annotateTimelineWithScore(state.timeline);

        const { localStatuses = [], opponentStatuses = [] } = state.playersStatus || {};
        
        console.log("state.timeline -->", JSON.stringify(state.timeline, null, 2));
        console.log("localStatuses -->", JSON.stringify(localStatuses, null, 2));
        console.log("opponentStatuses -->", JSON.stringify(opponentStatuses, null, 2));
        
        const updatedLocal = updatePlayersFromEvents(localStatuses, state.timeline, CHRONOLOGY_SIDE.LOCAL);
        const updatedOpponent = updatePlayersFromEvents(opponentStatuses, state.timeline, CHRONOLOGY_SIDE.OPPONENT);
        state.playersStatus = { localStatuses: updatedLocal, opponentStatuses: updatedOpponent };

        state.loadingTimeline = false;
        state.simulationCompleted = false;
        state.hasSimulated = true;

        const highlightCats = [
          CATEGORY_TYPES.START,
          CATEGORY_TYPES.GOAL,
          CATEGORY_TYPES.OWN_GOAL,
          CATEGORY_TYPES.FREE_KICK_GOAL,
          CATEGORY_TYPES.OFFSIDE,
          CATEGORY_TYPES.RED_CARD,
          CATEGORY_TYPES.FULL_TIME,
          CATEGORY_TYPES.YELLOW_CARD,
        ];
        state.highlights = state.timeline.reduce((acc, event, index) => {
          if (highlightCats.includes(event.category)) {
            acc.push({ ...event, id: index, thumbnail: "" });
          }
          return acc;
        }, []);
      })
      .addCase(fetchSimulationTimeline.rejected, (state, { payload }) => {
        state.errorTimeline = payload;
        state.loadingTimeline = false;
      });
  },
});

let intervalId = null;

export const startMatchTimeSimulation = () => (dispatch, getState) => {
  const { isMatchTimeRunning, matchTime } = getState().matchSimulation;
  if (isMatchTimeRunning || matchTime >= MAX_MATCH_TIME) return;
  dispatch(setMatchTimeRunning(true));
  intervalId = setInterval(() => {
    const { matchTime: current } = getState().matchSimulation;
    if (current >= MAX_MATCH_TIME) {
      clearInterval(intervalId);
      intervalId = null;
      dispatch(setMatchTimeRunning(false));
      return;
    }
    dispatch(incrementMatchTime());
  }, INTERVAL_DELAY);
};

export const stopMatchTimeSimulation = () => (dispatch) => {
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = null;
    dispatch(setMatchTimeRunning(false));
    dispatch(resetMatchTime());
  }
};

export const selectMatchSimulationState = (state) => state.matchSimulation;

export const {
  initiateSimulation,
  resetSimulationState,
  markNavigationTriggered,
  setError,
  storeLocalShotsData,
  storeOpponentShotsData,
  storePlayerHeatmap,
  incrementMatchTime,
  resetMatchTime,
  setMatchTimeRunning,
  setMatchPhase,
  updateScoreboard,
  updateGoals,
  updateRedCards,
} = matchSimulationSlice.actions;

export default matchSimulationSlice.reducer;
