
// middleware/parseSimulationResult.js

/**
 * Middleware to parse the simulation result from the API response.
 * This function ensures the response contains valid data and logs errors if the structure is incorrect.
 * @param {Object} response - The response object from the API call.
 * @returns {Object} - The parsed response data.
 * @throws {Error} - Throws an error if the response format is invalid.
 */
export const parseSimulationResult = (response) => {
    try {
      if (!response || typeof response !== 'object') {
        throw new Error('Response is not a valid object.');
      }
  
      // Ensure that the response contains the necessary properties
      if (!response.success) {
        throw new Error(`Simulation failed: ${response.message || 'Unknown error.'}`);
      }
  
      // Check if the expected data is present
      if (!response.timeline || !Array.isArray(response.timeline)) {
        throw new Error('The response does not contain a valid "timeline" array.');
      }
  
      console.log('Successfully parsed simulation result:', response);
      return response;
    } catch (error) {
      console.error('Error parsing simulation result:', error.message);
      throw new Error(`Failed to parse simulation result: ${error.message}`);
    }
  };
  