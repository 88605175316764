// middleware/formatTeamDataMiddleware.js

import {
  initiateMatchSimulation,
  fetchCompleteMatchData,
  setError,
  setMatchPhase,
} from "@/features/matchSimulation/matchSimulationSlice";
import { PHASES_OF_THE_MATCH } from "@/constants/phasesOfTheMatch";
import { updatePlayerEnergyLevels } from "@/features/teamSlice";
import { TEAM_TYPE } from "@/constants/teamTypes";
import { calculatePlayerPositions } from "../utils/playerUtils";

/**
 * DEFAULT_TACTICS
 * ---------------
 * For fallback if user fails to pick a custom tactic. 
 * Could be expanded if synergy_data suggests doping suspicion or HPC disclaimers, 
 * but we keep it simple for now.
 */
const DEFAULT_TACTICS = {
  formation: "4-3-3",
  playingStyle: "Balanced",
  offensiveStyle: { playersInBox: 2, style: "Balanced offense" },
  defensiveStyle: { depth: 20, width: 50, style: "Balanced defense" },
};

/**
 * formatTeamDataMiddleware
 * ------------------------
 * This middleware intercepts 'matchSimulation/initiateSimulation' to transform
 * local or opponent team data into a synergy-friendly structure (positions, roles,
 * doping disclaimers if synergy_data indicated, etc.) before calling the actual
 * initiateMatchSimulation thunk.
 */
const formatTeamDataMiddleware = (store) => (next) => (action) => {
  if (action.type !== "matchSimulation/initiateSimulation") {
    return next(action);
  }

  const state = store.getState();
  const { teamSelection, tactics } = state.team || {};
  const { rolesData = [] } = state.roles || {};
  const teamA = teamSelection?.local?.team;
  const teamB = teamSelection?.opponent?.team;
  const { scoreboard, phase } = state.matchSimulation;

  // Decide next phase in a naive manner
  let nextPhase = phase;
  switch (phase) {
    case PHASES_OF_THE_MATCH.FIRST_HALF:
      nextPhase = PHASES_OF_THE_MATCH.SECOND_HALF;
      break;
    case PHASES_OF_THE_MATCH.SECOND_HALF:
      nextPhase = PHASES_OF_THE_MATCH.EXTRA_TIME;
      break;
    case PHASES_OF_THE_MATCH.EXTRA_TIME:
      nextPhase = PHASES_OF_THE_MATCH.PENALTIES;
      break;
    default:
      nextPhase = PHASES_OF_THE_MATCH.FIRST_HALF;
  }
  store.dispatch(setMatchPhase(nextPhase));

  if (!teamA || !teamB) {
    console.error("[formatTeamDataMiddleware] Missing team data in state");
    store.dispatch(setError("Team data incomplete or unavailable"));
    return next(action);
  }

  // Helper to parse player skills from a "skill string"
  const formatPlayerSkills = (skills, position) => {
    if (!skills) return {};
    const skillArray = skills.split("|").map(Number);
    if (skillArray.length < 5) return {};
    const [s1, s2, s3, s4, s5] = skillArray;
    // Basic separation for GK vs outfield
    return position === "POR"
      ? {
          reflexes: s1 || 0,
          diving: s2 || 0,
          handling: s3 || 0,
          kicking: s4 || 0,
          speed: s5 || 0,
        }
      : {
          pace: s1 || 0,
          defense: s2 || 0,
          dribbling: s3 || 0,
          shooting: s4 || 0,
          passing: s5 || 0,
        };
  };

  // Connect roles with the actual players
  const mapRolesToPlayers = (roles, players, isTeamB = false) => {
    if (!players) return {};
    const mappedRoles = {};

    const roleMappings = {
      captain: "team-captain",
      left_corner: "left-corner-taker",
      right_corner: "right-corner-taker",
      left_free_kick: "left-free-kick-taker",
      right_free_kick: "right-free-kick-taker",
      long_free_kick: "long-distance-free-kick-taker",
      penalty_kick: "penalty-kick-taker",
    };

    const findByNumber = (n) =>
      players.starters.find((p) => p.number === n) ||
      players.substitutes.find((p) => p.number === n);

    if (isTeamB) {
      if (typeof roles !== "object") return mappedRoles;
      Object.entries(roles).forEach(([rk, number]) => {
        if (number == null) return;
        const pl = findByNumber(number);
        if (pl && roleMappings[rk]) {
          mappedRoles[roleMappings[rk]] = pl.name || "Unknown Player B";
        }
      });
    } else {
      if (!Array.isArray(roles)) return mappedRoles;
      roles.forEach(({ roleKey, playerNumber }) => {
        if (playerNumber == null) return;
        const p = findByNumber(playerNumber);
        if (p && roleMappings[roleKey]) {
          mappedRoles[roleMappings[roleKey]] = p.name || "Unknown Player A";
        }
      });
    }

    return mappedRoles;
  };

  // Format a single team's data for synergy usage
  const formatTeam = (team, isTeamB = false) => {
    if (!team) return null;

    const formatPlayers = (playerArr = [], isStarter = false) =>
      playerArr.map((player) => ({
        name: player.playerName?.trim() || "Unknown Player",
        position: player.position || "ND",
        number: player.playerNumber || "",
        skills: formatPlayerSkills(player.playerSkills, player.position),
        instruction: player.playerInstruction?.value || "",
        status: player.status || {},
        ...(isStarter && {
          fieldPosition: {
            x: Math.round(player.vectorPosition?.x || 0),
            y: Math.round(player.vectorPosition?.y || 0),
          },
        }),
      }));

    const formattedPlayers = {
      starters: formatPlayers(team.players, true),
      substitutes: formatPlayers(team.substitutes, false),
    };

    const roles = mapRolesToPlayers(
      isTeamB ? team.teamRoles : rolesData,
      formattedPlayers,
      isTeamB
    );

    const finalTeam = {
      teamName:
        team.fullName?.trim() ||
        team.teamName?.trim() ||
        `Equipo ${isTeamB ? "Visitante" : "Local"}`,
      players: formattedPlayers,
      roles,
    };

    // If it's Team B, just unify or generate generic tactics
    if (isTeamB) {
      finalTeam.tactics = {
        ...DEFAULT_TACTICS,
        formation: team.formation || DEFAULT_TACTICS.formation,
        playingStyle: `Generic style for ${team.fullName || team.teamName}`,
      };
    } else {
      // For Team A, merge with the selected tactic from state
      const {
        selectedGameStyleId,
        playingStyles = [],
        defensiveStyles = [],
        offensiveStyles = [],
      } = tactics || {};
      const chosenPS = playingStyles.find((ps) => ps.id === selectedGameStyleId);
      if (!chosenPS) {
        console.warn(
          "[formatTeamDataMiddleware] No matching playingStyle, using default."
        );
        finalTeam.tactics = DEFAULT_TACTICS;
      } else {
        const styleVal = chosenPS.value || "Balanced";
        const defObj = defensiveStyles.find(
          (ds) => ds.id === chosenPS.defense?.styleId
        ) || { value: "Balanced defense" };
        const offObj = offensiveStyles.find(
          (os) => os.id === chosenPS.attack?.styleId
        ) || { value: "Balanced offense" };

        finalTeam.tactics = {
          formation: team.formation || "4-3-3",
          playingStyle: styleVal,
          offensiveStyle: {
            playersInBox: chosenPS.attack?.playersInBox || 2,
            style: offObj.value,
          },
          defensiveStyle: {
            depth: chosenPS.defense?.depth || 20,
            width: chosenPS.defense?.width || 50,
            style: defObj.value,
          },
        };
      }
    }
    return finalTeam;
  };

  const formatAndSanitizeTeam = (team) => {
    if (!team) return null;
    const ret = { ...team };
    if (ret.crestRef && typeof ret.crestRef === "object") {
      ret.crestRef = ret.crestRef.id || null;
    }
    return ret;
  };

  // Format local (Team A) and opponent (Team B)
  const formattedTeamA = formatTeam(teamA);
  const formattedTeamB = formatTeam(teamB, true);

  if (!formattedTeamA || !formattedTeamB) {
    console.error("[formatTeamDataMiddleware] Incomplete data after formatting");
    store.dispatch(setError("Team data incomplete after formatting"));
    return next(action);
  }

  const sanitizedA = formatAndSanitizeTeam(formattedTeamA);
  const sanitizedB = formatAndSanitizeTeam(formattedTeamB);

  const matchData = {
    match: {
      teamA: sanitizedA,
      teamB: sanitizedB,
      phase: nextPhase,
      scoreboard,
    },
  };

  // Finally dispatch the real simulation action, after formatting
  store
    .dispatch(initiateMatchSimulation(matchData))
    .unwrap()
    .then((result) => {
      const { timeline } = result;
      if (!Array.isArray(timeline)) {
        throw new Error("Timeline from simulation is invalid or empty.");
      }

      // If we want a second call for advanced HPC synergy doping checks or post-match stats, do it:
      // store.dispatch(fetchCompleteMatchData({ timeline, synergyHPC: synergy_data?.dopingSuspicion }));

      console.log("[formatTeamDataMiddleware] Simulation complete, timeline length:", timeline.length);
    })
    .catch((error) => {
      console.error("[formatTeamDataMiddleware] Error in match simulation:", error);
      store.dispatch(setError("Failed to complete match simulation: " + error.message));
    });

  return next(action);
};

export default formatTeamDataMiddleware;
