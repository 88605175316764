// @/utils/updatePlayersFromEvents.js

import { CATEGORY_TYPES } from "@/utils/categories";

/**
 * updatePlayersFromEvents
 * -----------------------
 * Applies timeline events to modify each player's status if the event side
 * matches the specified `targetSide` (e.g., CHRONOLOGY_SIDE.LOCAL or OPPONENT).
 * 
 * A single pass iterates over all timeline events:
 *   - GOAL or FREE_KICK_GOAL => increment `goals`.
 *   - OWN_GOAL               => mark `hasScoredOwnGoal = true`.
 *   - YELLOW_CARD            => if player lacks a yellow => hasYellowCard; else => second yellow => hasDoubleYellowCard & hasRedCard.
 *   - RED_CARD               => mark `hasRedCard = true`.
 *   - SECOND_YELLOW_CARD     => second yellow => hasDoubleYellowCard & hasRedCard = true.
 *   - SUBSTITUTION           => `hasSubstitution = true`.
 * 
 * Non-status events (FOUL, COMMENTARY, etc.) produce no change.
 * 
 * @param {Array} players An array of player objects, each with:
 *   {
 *     id: number|string,  // unique ID
 *     status: {
 *       goals?: number,
 *       hasYellowCard?: boolean,
 *       hasDoubleYellowCard?: boolean,
 *       hasRedCard?: boolean,
 *       hasSubstitution?: boolean,
 *       hasScoredOwnGoal?: boolean,
 *       energyLevel?: number
 *     },
 *     ...
 *   }
 * @param {Array} timeline The match timeline from the synergy-based LLM or similar.
 * @param {string} targetSide One of CHRONOLOGY_SIDE.LOCAL or CHRONOLOGY_SIDE.OPPONENT.
 * @returns {Array} A new array of players (immutably cloned) reflecting updated status fields.
 */
export function updatePlayersFromEvents(players, timeline, targetSide) {
  // Build a mutable Map for quick lookups, deep clone to avoid side effects
  const playerMap = new Map(players.map((p) => [p.id, structuredClone(p)]));

  const applyYellowCard = (plyr) => {
    if (!plyr.status.hasYellowCard) {
      plyr.status.hasYellowCard = true;
    } else {
      plyr.status.hasDoubleYellowCard = true;
      plyr.status.hasRedCard = true;
    }
  };

  for (const event of timeline) {
    const { category, side, player } = event;
    // Skip if no valid player ID or side mismatches targetSide
    if (!player?.id || side !== targetSide) continue;

    const currentPlayer = playerMap.get(player.id);
    if (!currentPlayer) continue; // Player not found in the roster for this side

    switch (category) {
      case CATEGORY_TYPES.GOAL:
      case CATEGORY_TYPES.FREE_KICK_GOAL:
        currentPlayer.status.goals = (currentPlayer.status.goals || 0) + 1;
        break;

      case CATEGORY_TYPES.OWN_GOAL:
        currentPlayer.status.hasScoredOwnGoal = true;
        break;

      case CATEGORY_TYPES.YELLOW_CARD:
        applyYellowCard(currentPlayer);
        break;

      case CATEGORY_TYPES.SECOND_YELLOW_CARD:
        currentPlayer.status.hasDoubleYellowCard = true;
        currentPlayer.status.hasRedCard = true;
        break;

      case CATEGORY_TYPES.RED_CARD:
        currentPlayer.status.hasRedCard = true;
        break;

      case CATEGORY_TYPES.SUBSTITUTION:
        currentPlayer.status.hasSubstitution = true;
        break;

      default:
        // FOUL, COMMENTARY, MISSED_OPPORTUNITY, etc. => no direct status update
        break;
    }

    playerMap.set(player.id, currentPlayer);
  }

  return Array.from(playerMap.values());
}
